module.exports = {
   bankCard: 'ربط العنوان',
bank_card: 'ربط العنوان',
can_withdraw_money1: 'الرصيد المتاح',
dongjie_ye1: 'المبلغ قيد المعالجة',
tixian1: 'شحن',
xyz: 'نقاط السمعة',
jinrishou5: 'العمولة المكتسبة اليوم',
jinrishou4: 'الرصيد المتاح',
jinridan3: 'المبلغ قيد المعالجة',
jinridan2: 'غير مكتمل',
jinridan1: 'الكل',
    home:'الصفحة الرئيسية',
    about:'عن',
    order:'طلب',
    record:'سجل الطلب',
    user:'مِلكِي',
    about_text1:'سلع ذات جودة',
    about_text2:'المزيد من المنتجات',
    order_title:'مهمة',
    zongzichan:'إجمالي الأصول',
    jinridan:'كمية الطلب',
    tiyanjin:'صندوق الخبرة',
    jinrishou:'دخل اليوم',
    jintuandui:'لجنة فريق اليوم',
    dianjigou:'بدء المهمة',
    adianjigou:'يُقدِّم',
    order_record:'سجل الطلب',
    all:'الجميع',
    undone:'التراجع',
    completed:'مكتمل',
    score:'نتيجة',
    benutzer:'مستخدم',
    chongzhi:'فوق حتى',
    tixian:'ينسحب',
    details:'تفاصيل الحساب',
    password:'كلمة سر الدخول',
    password_jy:'كلمة مرور السحب',
    notify:'إشعار النظام',
    kefu:'خدمة الزبائن',
    invite:'ادعو أصدقاء',
    team:'تقرير الفريق',
    language:'لغة',
    member:'ترقية الأعضاء',
    balance:'رصيد الحساب',
    quit:'يترك',
    invite_text1:'???? ???????? ???? ??????',
    invite_code:'شفرة الدعوة',
    invite_btn:'???? ??????: ???? ????',
    copy_s:'تكرار',
    copy_b:'نسخ فشل',
    grade:'مستوى العضوية',
    my_money:'رصيد الحساب',
    open_member:'انضم إلى العضوية',
    withdraw_num:'عدد الانسحاب',
    day:'يوم',
    withdraw_quota:'انسحاب',
    order_num:'كمية النظام',
    profit_scale:'نسبة العمولة',
    member_time:'عضو دائم وفعال',
    confirm_pay:'تأكيد الدفع',
    order_sub:'أكد الطلب',
    user_info:'معلومات المستخدم',
    avatar:'الصورة الرمزية',
    username:'اسم المستخدم',
    set_up:'إعداد على الفور',
    revise_name:'تعديل الاسم',
    username_hint:'?????? ????? ??? ????????',
    user_account:'حساب المستخدم',
    add_money:'فوق حتى',
    add_money_num:'إعادة شحن المبلغ',
    add_money_hint1:'1. ??? ?? ???? ???? ????? ?????? ?? ???? ????? ? ???? ??? ??? ?????? ???? ????????',
    add_money_hint2:'2. ??? ?? ???? ????? ????????? ? ????? ??????? ???? ??????? ???????? ?? ??? ???????? ??????',
    add_money_hint3:'???? ??????? ????? ??????? ?????? ??? ??????',
    add_money_record:'سجل إعادة الشحن',
    withdraw_record:'سجل السحب',
    withdraw_money:'كمية السحب',
    can_withdraw_money:'يمكن ذكر المبلغ',
    order_number:'SN',
    money:'كمية',
    bank:'BANK',
    transaction_flow:'رقم تدفق التداول',
    already_paid:'لقد دفعت',
    upload_pay_img:'????? ???? ??????',
    order_details:'تفاصيل الحساب',
    old_password:'???? ?????? ???????',
    new_password:'كلمة مرور جديدة',
    repeat_password:'????? ???? ??????',
    enter:'تفضل',
    password_hint:'???? ?????? ???? ?????? ? ???? ??????? ????? ??????? ??? ???? ???? ??????',
    submit:'إرسال',
    bank:'فتح حساب',
    bank_card_num:'رقم بطاقة البنك',
    bank_card_name:'اسم حامل البطاقة',
    team:'تقرير الفريق',
    today:'اليوم',
    yesterday:'أمس',
    week:'هذا الاسبوع',
    scale:'حَجم',
    team_people:'عدد الفرق',
    team_order_scale:'لجنة أمر الفريق',
    open_bank_name:'إسم الحساب',
    phone:'رقم التليفون',
    user_password:'كلمة المرور',
    login:'تسجيل دخول',
    register:'يسجل',
    password_qr:'????? ???? ??????',
    pwd_no_same:'???? ???? ??? ???????',
    loading:'تحميل',
    freeze:'تجميد',
    pending:'قيد الانتظار',
    order_hao:'رقم الأمر',
    order_time:'مرة واحدة',
    price:"سعر الوحدة",
    order_total_price:'من أجل الكاملة',
    scale:'عمولة',
    level:'مستوى العضوية',
    level_up:'ترقية الأعضاء',
    pay_way:'????? ????? ?????',
    money_min:'صغير جدا',
    pay_no:'?????? ????? ????? ????? ?????',
    // 新加
    team_all:'الجميع',
    team_yi:'مستوى اول',
    team_er:'المرحلة الثانوية',
    team_san:'ثلاثة مستوى',
    close_order:'الغاء الطلب',
    shouru:'دخل',
    zhichu:'المصروفات',
    welcome:'مرحباً',
    order_kong:'?? ???? ?? ???? ??? ????? ??????',
    quer:'يتأكد',
    quxiao:'يلغي',
    qianbaodi:'عنوان محفظة',
    xingming:'اسم',
    bank_chong:'?? ???? ????? ?????',
    introduce:'ملف الشركة',
    platform:'قواعد المنصة',
    tixianzhang:'حساب السحب',
    xuanze:'اختر من فضلك',
    xiayiye:'???? ?????? ?????? ???????',
    // 新加2
    certificate:'شهادة',
    faq:'FAQ',
    tc:'T&C',
    guanyu_wm:'معلومات عنا',
    zuixin_sj:'آخر حادث',
    usdt_tx:'انسحاب USDT',
    bank_tx:'??? ??????? ????????',
    bank_tx_kf:'???? ??????? ????? ??????? ??? ????????',
    dongjie_ye:'المبلغ المجمد',
    zanwu_sj:'لايوجد بيانات'
  }