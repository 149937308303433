module.exports = {

    banner1_text:'Life is too short to spend time with people who suck the happiness out of you. If someone wants you in their life, they’ll make room for you.',
    leistungsumfang:'Leistungsumfang',
    leistungsumfang_text:'Currently serving 100.000+ custormers and receiving good reviews',
    leistungsumfang_free:'FREE TRANSFER',
		bankCard: 'Bind Address',
bank_card: 'Bind Address',
can_withdraw_money1: 'Available Balance',
dongjie_ye1: 'Pending Amount',
tixian1: 'Recharge',
xyz: 'Credit Score',
jinrishou5: 'Earned Commission Today',
jinrishou4: 'Available Balance',
jinridan3: 'Pending Amount',
jinridan2: 'Unfinished',
jinridan1: 'All',
    home: 'Home',
about: 'About',
order: 'Order',
record: 'Order Record',
user: 'My ',
    about_text1:'quality goods',
    about_text2:'More products',
    order_title: 'Task',
    zongzichan: 'Total Assets',
    jinridan: 'Order Quantity',
    tiyanjin: 'Trial Fund',
    jinrishou: `'Today's Income`,
    jintuandui: `'Today's Team Commission`,
    dianjigou: 'Start Task',
    adianjigou: 'Submit',
    order_record: 'Order Records',
    all: 'All',
    undone: 'Incomplete',
    completed: 'Completed',
    score: 'Score',
    benutzer: 'User',
    chongzhi: 'Recharge',
    tixian: 'Withdrawal',
    details: 'Account Details',
    password: 'Login Password',
    password_jy: 'Transaction Password',
    notify: 'System Notification',
    kefu: 'Customer Service',
    invite: 'Invite Friends',
    team: 'Team Report',
    language: 'Select Language',
    member: 'Membership Upgrade',
    balance: 'Account Balance',
    quit: 'Logout',
    invite_text1: 'Invite Friends to Earn Cash',
    invite_code: 'Invitation Code',
    invite_btn: 'Invitation Link: Click to Copy',
    copy_s: 'Copy Successful',
copy_b: 'Copy Failed',
grade: 'Membership Level',
my_money: 'My Account Balance',
open_member: 'Open Membership',
withdraw_num: 'Number of Withdrawals',
day: 'Day',
withdraw_quota: 'Withdrawal Limit',
order_num: 'Number of Orders Received',
profit_scale: 'Commission Rate',
member_time: 'Membership Permanently Valid',
confirm_pay: 'Confirm Payment',
order_sub: 'Submit Order',
user_info: 'User Information',
avatar: 'Avatar',
username: 'Username',
set_up: 'Set Up Now',
revise_name: 'Modify Name',
username_hint: 'Please Enter Username',
user_account: 'User Account',
add_money: 'Add Money',
add_money_num: 'Amount to Recharge',
add_money_hint1: '1. Payment amount must match the order amount, otherwise it will not arrive automatically.',
add_money_hint2: '2. If recharge or withdrawal is not received, please consult your superior or customer service for resolution.',
add_money_hint3: 'Contact customer service for fees.',
add_money_record: 'Recharge Records',
withdraw_record: 'Withdrawal Records',
withdraw_money: 'Withdrawal Amount',
can_withdraw_money: 'Available Withdrawable Amount',
order_number: 'SN',
money: 'Amount',
bank: 'BANK',
transaction_flow: 'Transaction ID',
already_paid: 'I have paid',
upload_pay_img: 'Upload Payment Screenshot',
order_details: 'Account Details',
old_password: 'Old Password',
new_password: 'New Password',
repeat_password: 'Repeat Password',
enter: 'Please Enter',
password_hint: 'Please remember your password. If you forget it, please contact customer service.',
submit: 'Submit',
bank: 'Bank',
bank_card_num: 'Bank Card Number',
bank_card_name: 'Cardholder Name',
team: 'Team Report',
today: 'Today',
yesterday: 'Yesterday',
week: 'This Week',
scale: 'Scale',
team_people: 'Team Size',
team_order_scale: 'Team Order Commission',
open_bank_name: 'Account Holder Name',
phone: 'Phone Number',
user_password: 'Password',
login: 'Log In',
register: 'Register',
password_qr: 'Confirm Password',
pwd_no_same: 'Passwords Do Not Match',
loading: 'Loading',
freeze: 'Freeze',
pending: 'Pending',
order_hao: 'Order Number',
order_time: 'Order Time',
price: 'Unit Price',
order_total_price: 'Total Order Amount',
level: 'Membership Level',
level_up: 'Upgrade Membership',
pay_way: 'Payment Method',
money_min: 'Amount Too Small',
pay_no: 'Please Select Payment Method',
// New Additions
team_all: 'All',
team_yi: 'Level One',
team_er: 'Level Two',
team_san: 'Level Three',
close_order: 'Cancel Order',
shouru: 'Income',
zhichu: 'Expenditure',
welcome: 'Welcome',
order_kong: 'Order Number Cannot Be Empty',
quer: 'Confirm',
quxiao: 'Cancel',
qianbaodi: 'Wallet Address',
xingming: 'Name',
bank_chong: 'Cannot Bind Repeatedly',
introduce: 'Company Introduction',
platform: 'Platform Rules',
tixianzhang: 'Withdrawal Account',
xuanze: 'Please Select',
xiayiye: 'Click to Load Next Page',
// Newly Added 2
certificate: 'Certificate',
faq: 'FAQ',
tc: 'T&C',
guanyu_wm: 'About Us',
zuixin_sj: 'Latest Events',
usdt_tx: 'USDT Withdrawal',
bank_tx: 'Bank Card Withdrawal',
bank_tx_kf: 'Please Contact Online Customer Service',
dongjie_ye: 'Frozen Balance',
zanwu_sj:'No data'
  }