module.exports = {
 bankCard: 'Adresse de liaison',
bank_card: 'Adresse de liaison',
can_withdraw_money1: 'Solde disponible',
dongjie_ye1: 'Montant en attente',
tixian1: 'Recharge',
xyz: 'Score de crédit',
jinrishou5: `Commission gagnée aujourd'hui`,
jinrishou4: 'Solde disponible',
jinridan3: 'Montant en attente',
jinridan2: 'Non terminé',
jinridan1: 'Tout',
  home: 'Accueil',
  about: 'à propos',
  order: 'Commande',
  record: 'Enregistrement',
  user: 'Utilisateur',
    about_text1:'produits de qualité',
    about_text2:'Plus de produits',
    order_title: 'Tache',
	
zongzichan: 'Total des actifs',
jinridan: 'Nombre de commandes',
tiyanjin: `Fonds d'expérience`,
jinrishou: 'Revenu du jour',
jintuandui: `Commission d'équipe du jour`,
dianjigou: 'Démarrer la tache',
adianjigou: 'Soumettre',
order_record: 'Historique des commandes',
all: 'Tout',
undone: 'Non terminé',
completed: 'Terminé',
score: 'Score',
benutzer: 'Utilisateur',
chongzhi: 'Recharge',
tixian: 'Retrait',
details: 'Détails du compte',
password: 'Mot de passe de connexion',
password_jy: 'Mot de passe de transaction',
notify: 'Notification système',
kefu: 'Service client',
invite: 'Inviter des amis',
team: `Rapport d'équipe`,
language: 'Choisir la langue',
member: 'Mise à niveau du membre',
balance: 'Solde du compte',
quit: 'Déconnexion',
invite_text1: `Invitez des amis pour gagner de l'argent`,
invite_code: `Code d'invitation`,
invite_btn: `Lien d'invitation : cliquez pour copier`,
copy_s: 'Copié avec succès',
copy_b: 'échec de la copie',
grade: 'Niveau de membre',
my_money: 'Mon solde de compte',
open_member: 'Ouvrir un abonnement',
withdraw_num: 'Nombre de retraits',
day: 'Jour',
withdraw_quota: 'Limite de retrait',
order_num: 'Nombre de commandes acceptées',
profit_scale: 'Taux de commission',
member_time: 'Adhésion permanente',
confirm_pay: 'Confirmer le paiement',
order_sub: 'Soumettre la commande',
user_info: 'Informations utilisateur',
avatar: 'Avatar',
username: `Nom d'utilisateur`,
set_up: 'Configurer maintenant',
revise_name: 'Modifier le nom',
username_hint: `Veuillez remplir le nom d'utilisateur`,
user_account: 'Compte utilisateur',
add_money:`Ajouter de l'argent`,
add_money_num: 'Montant à ajouter',
add_money_hint1: '1. Le montant payé doit correspondre au montant de la commande, sinon il ne sera pas crédité automatiquement.',
add_money_hint2: `2. Si vous n'avez pas re?u de crédit ou de retrait, veuillez contacter votre supérieur ou le service client pour résoudre d'autres problèmes.`,
add_money_hint3: 'Contactez le service client pour les frais.',
add_money_record: 'Historique des recharges',
withdraw_record: 'Historique des retraits',
withdraw_money: 'Montant à retirer',
can_withdraw_money: 'Montant pouvant être retiré',
order_number: 'Numéro de commande',
money: 'Argent',
bank: 'BANQUE',
transaction_flow: 'Numéro de transaction',
already_paid: `J'ai déjà payé`,
upload_pay_img: `Télécharger la capture d'écran du paiement`,
order_details: 'Détails de la commande',
old_password: 'Ancien mot de passe',
new_password: 'Nouveau mot de passe',
repeat_password: 'Répéter le mot de passe',
enter: 'Entrez',
password_hint: `Veuillez vous rappeler de votre mot de passe. Si vous l'oubliez, veuillez contacter le service client.`,
submit: `oumettre`,
bank: 'Banque',
bank_card_num: 'Numéro de carte bancaire',
bank_card_name: 'Nom du titulaire de la carte',
team: `Rapport d'équipe`,
today: `Aujourd'hui`,
yesterday: 'Hier',
week: 'Cette semaine',
scale: 'échelle',
team_people: `Nombre de personnes dans l'équipe`,
team_order_scale: `Commission des commandes d'équipe`,
open_bank_name: 'Nom du titulaire du compte',
phone: 'Numéro de téléphone',
user_password: 'Mot de passe',
login: 'Connexion',
register: `S'inscrire`,
password_qr: 'Confirmer le mot de passe',
pwd_no_same: 'Les mots de passe ne correspondent pas',
loading: 'Chargement',
freeze: 'Gelé',
pending: 'En attente',
order_hao: 'Numéro de commande',
order_time: 'Heure de la commande',
price: 'Prix',
order_total_price: 'Montant total de la commande',
level: 'Niveau de membre',
level_up: 'Mise à niveau du membre',
pay_way: 'Méthode de paiement',
money_min: 'Montant trop faible',
pay_no: 'Veuillez choisir une méthode de paiement',
// Nouveaux ajouts
team_all: 'Tout',
team_yi: 'Niveau un',
team_er: 'Niveau deux',
team_san: 'Niveau trois',
close_order: 'Annuler la commande',
shouru: 'Revenu',
zhichu: 'Dépense',
welcome: 'Bienvenue',
order_kong: 'Le numéro de commande ne peut pas être vide',
quer: 'Confirmer',
quxiao: 'Annuler',
qianbaodi: 'Adresse du portefeuille',
xingming: 'Nom',
bank_chong: 'Ne peut pas être lié à nouveau',
introduce: `Présentation de l'entreprise`,
platform: 'Règles de la plateforme',
tixianzhang: 'Compte de retrait',
xuanze: 'Veuillez sélectionner',
xiayiye: 'Cliquez pour charger la page suivante',
// Nouveaux ajouts 2
certificate: 'Certificat',
faq: 'FAQ',
tc: 'T&C',
guanyu_wm: 'à propos de nous',
zuixin_sj: 'Derniers événements',
usdt_tx: 'Retrait USDT',
bank_tx: 'Retrait par carte bancaire',
bank_tx_kf: 'Veuillez contacter le service client en ligne',
dongjie_ye: 'Solde gelé',
zanwu_sj:'Pas de données'
  }