import { useI18n } from 'vue-i18n';
import store from '@/store/index';
import { vantLocales } from '@/i18n/i18n';
import { get_lang, is_recharge } from '@/api/api';
export default {
  setup() {
    // 多语言
    const {
      locale
    } = useI18n();
    let lang = localStorage.getItem('lang') || 'en-en';
    locale.value = lang;
    store.dispatch('changelang', lang);
    vantLocales(lang);
    get_lang().then(res => {
      // //  //  console.log('lang',res)
    });
    is_recharge().then(res => {
      if (res.open_recharge) {
        return store.dispatch('changeOpenRecharge', true);
      }
      store.dispatch('changeOpenRecharge', false);
    });
    // 弹窗
    sessionStorage.setItem('app_pop', true);
  }
};